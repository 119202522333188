.contract-comparison-main-container {
  background: #f6f7f7;
}

.contract-comparison-header-One {
  background: #f3fcfe;
  display: flex;
  align-items: center;
  justify-content: end;
  border: 1px solid #99cecb;
  min-height: 40px;
  padding: 16px;
}

.contract-comparison-header-two {
  background: #e1e9ef;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  border-bottom: 2px;
  // height: 94px;
  border-radius: var(--b-radius);
  padding: 12px 12px;
  gap: 12px;
  // border: 1px solid red;
  flex-wrap: wrap;
}

.contact-header-box1 {
  width: 180px;
  height: 70px;
  // padding-bottom: 24px;
  // border: 1px solid rgb(0, 255, 0);
}
.contact-header-box2 {
  width: 196px;
  height: 70px;
  // padding-bottom: 24px;
  // border: 1px solid rgb(0, 217, 255);
}
// .contact-header-box3 {
//   width: 371px;
//   height: 70px;
//   padding-bottom: 24px;
//   border: 1px solid rgba(0, 255, 42, 0.715);
// }
// .contractComparison-Divider-dropdown {
//   border-width: 1.6;
//   border-color: silver;
//   height: 50px;
//   // margin: 0px 18px;
//   border: 1px solid red;
// }
.contract-comparison-alignment {
  padding-left: 0px;
  // border: 1px solid red;
}
// .contact-header-box4 {
//   // width: 186px;
//   // height: 70px;
//   padding-bottom: 24px;
//   border: 1px solid rgb(51, 255, 0);
// }
.contract-comparison-dropdown {
  height: 70px;
}
.contact-header-box5 {
  width: 186px;
  height: 70px;
  // padding-bottom: 24px;
}

.contract-subbox {
  display: flex;
  // padding-top: 4px;
  // margin-right: 20px;
}
.contract-subbox-2 {
  display: flex;
  // padding-top: 4px;
  gap: 12px;
  // margin-right: 20px;
}

.contract-comparison-dropdown-align-d {
  min-width: 120px !important;
  .ant-select-selector {
    border-radius: 2px  0px 0px 2px;
    color: #37424a;;
    border: 1px solid #d3d6db !important;
  }
}

.contract-comparison-multi-dropdown-align{
 width: 260px !important;
  .ant-select-selector {
    border-radius: var(--b-radius);
  }
}

.contract-comparison-dropdown-align{
  border-color:#d3d6db ;
  border-width: 1px 1px 1px 0px;
  color: #00857c;
  background: #ffffff;
  border-radius:  0px  2px 2px 0px;
  // padding: 4px 4px 0px 4px;
}
.contract-comparison-dropdown-align-template-button{
  border-color:#d3d6db ;
  border-width: 1px 1px 1px 0px;
  color: #00857c;
  background: #ffffff;
  border-radius:  0px  2px 2px 0px;
  // border: 1px solid red;
}



.contract-comparison-text {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 8px;
}

.Contract-dropdown-1 {
  min-width: 136px !important;
  border-radius: var(--b-radius);
  height: 42px;
  .ant-select-selector {
    border-radius: var(--b-radius);
  }
}
.Contract-dropdown-2 {
  min-width: 136px !important;
  border-radius: var(--b-radius);
  height: 42px;
  .ant-select-selector {
    border-radius: var(--b-radius);
  }
}
.Contract-dropdown-3 {
  // min-width: 300px !important;
  border-radius: var(--b-radius);
  // height: 42px;
  .ant-select-selector {
    border-radius: var(--b-radius);
  }
}
.Contract-dropdown-4 {
  // min-width: 156px !important;
  border-radius: var(--b-radius);
  // height: 42px;
  .ant-select-selector {
    border-radius: var(--b-radius);
  }
}
.Contract-dropdown-5 {
  min-width: 156px !important;
  border-radius: var(--b-radius);
  height: 42px;
  .ant-select-selector {
    border-radius: var(--b-radius);
  }
}

//////
.contract-comparison-table {
  padding: 16px;
  margin: 16px;
  border: 1px solid #CDD7DF;
  background-color: #ffffff;
}

.contract-comparison-table
  .ant-table-wrapper
  .ant-table-thead
  th.ant-table-column-has-sorters {
  border-inline-end: 1px solid #f0f0f0 !important;
}
.contract-comparison-table
  .ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  border-inline-end: 1px solid #cdd7df !important;
}
.contract-comparison-table
  .reusable-grid
  .ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  background-color: #e6e7e9;
}

.top-row {
  background-color: #ffffff !important;
}

.contract-comparison-table
  .reusable-grid
  .ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  background-color: #e6e7e9;
  // border-inline-end: 1px solid #940a0a !important;
  border-start-end-radius: initial;
  border-start-start-radius: initial;
}

.contract-comparison-table table > thead > :nth-child(1) > th {
  background-color: white !important;
  border-inline-end: 2px solid #cdd7df;
}

.copy-contract-comparison-button{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding:0px 16px;
}

