.x-axis-label {
    font-size: 14px;
    font-weight: bold;
    fill: #333;
  }
  
  .y-axis-label {
    font-size: 14px;
    font-weight: bold;
    fill: #333;
  }

  .contract-comparison-graph .ant-card-body{
    padding: 16px;
  }

  .GraphText-Heading{
    font-size: 20px;
    font-weight: 700;
    height: 40px;
    display: flex;
    align-items: center;
    color: #37424A;
  }

  .GraphText-sub-Heading{
    font-size: 16px;
    font-weight: 400;
    color: #A7AEB4;
    height: 40px;
  }