.define-wac-modal-Header {
  height: 40px;
  background: #f3fcfe;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: end;
  border-top: 1px solid #99cecb;
  border-bottom: 1px solid #99cecb;
}

.custom-modal {
  .ant-modal-content {
    border-radius: var(--b-radius);
    padding: 0px;
    background-color: #f6f7f7;
    padding-bottom: 16px;
  }

  .ant-modal-body {
    margin: 16px 16px 0px 16px;
    background-color: #ffffff;
  }

  .ant-modal-header {
    border-radius: var(--b-radius);
    display: flex;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 0px;
  }
  .ant-modal-title {
    color: #000000;
    font-weight: 700;
    line-height: 28px;
    font-size: 20px;
    font-style: normal;
  }

  .ant-modal-footer {
    margin: 0px 16px 16px 16px;
    background-color: #ffffff;
  }

  .button-wrapper {
    display: flex;
  }
}

.alignment-template-Modal-Table .ant-table-tbody > tr > td {
  border-bottom: 1x solid #cdd7df;
  width: 100px;
}

// .wac-table .reusable-grid .ant-table-wrapper .ant-table.ant-table-small .ant-table-filter-trigger{
//   margin-inline-end: 125px;
// }

// .wac-table .reusable-grid .ant-table-wrapper .ant-table-column-sorters {
//   display: inline-flex;
// }
