.wac-header {
  min-height: 38px;
  max-height: 100px;
  background: #F3FCFE;
  display: grid;
  align-items: center;
  padding: 16px;
  border-top: 1px solid #99cecb;
  border-bottom: 1px solid #99cecb;
  margin-top: 12px;
}

.wac-header .ant-row {
  display: flex;
  align-items: center;
  gap:10px;
  // padding-top: 10px;
}

.wac-table {
  margin: 16px 16px 14px 16px;
  background-color: white;
  padding: 16px 16px 16px 16px;
  border: 1px solid #E1E9EF;
  height: 60vh;
}

.left-align {
  display: flex;
  align-items: center;
  gap: 24px;
  // margin-left: -15px;
}

.right-align {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dropdown-text-wac {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: var(--text-main-color);
}

.wac-type-modal-dropdown {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  // margin-left: 10px; /* Add margin for spacing */
  border-radius: var(--b-radius);
  color: var(--text-main-color);
  height: 40px;
  
}

.ant-select .ant-select-arrow{
  color: var(--text-main-color);
}

.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector{
  border: 1px solid #D3D6DB;
    font-size: 16px;
  color: var(--text-main-color);
  border-radius: var(--b-radius);
  // padding: 8px 12px;
  font-weight: 400;
  height: 40px;
  // padding:8px 12px ;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item{
  font-weight: 400;
}

.custom-dropdown .ant-select-selector {
  border-radius: var(--b-radius);
  font-size: 16px;
  
}


.button-icon-wac {
  border: 1px solid #00857c;
  padding: 8px 16px;
  font-size: 16px;
  color: #00857c;
  border-radius: var(--b-radius);
  height: 40px;
}

.button-apply {
  border-radius: var(--b-radius);
  box-shadow: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 8px 16px;
  background-color: #00857c;
}

// .wac-table:where(.css-dev-only-do-not-override-a6vam3).ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container{
//   border: 1px solid red;
// }

.radio-text{
  font-size: 16px;
  font-weight: 400;
}

.wac-table .reusable-grid .ant-table-wrapper .ant-table.ant-table-small .ant-table-filter-trigger{
  margin-inline-end: 125px;
}

.ant-table-wrapper .ant-table-column-sorters {
  display: inline-flex;
}

.ant-radio-wrapper span.ant-radio+*{
     padding-inline-start: 0px;
     padding-inline-end: 0px;
}   

.ant-radio-wrapper{
  gap: 8px;
  margin-inline-end: 0px;
}