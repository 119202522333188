

 

  .table-create-scenario-modal table>thead>tr>th {
    background-color: var(--bgc-table-head) !important;
  }

  // .table-create-scenario-modal table>thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
  //   border: 0px;
  //   height: 0px;
  // } 

  // .table-create-scenario-modal .ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
  //   border: 0px;
  //   height: 0px;
  // }

  .table-create-scenario-modal .ant-table-wrapper .ant-table-cell, .ant-table-wrapper .ant-table-thead>tr>th{
    padding: 12px;
  }

  .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child{
    border-radius: 0px;
  }
  .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child{
    border-radius: 0px;
  }