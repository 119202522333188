.AccountSummaryDD {
        display: flex;
        align-items: center;
        gap: 8px;
    }



.AccountSummaryDD .dropdown-label{
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    // padding-left: 8px;
    // padding-right: 10px;
    width: 100%;
}

.AccountSummaryDD .dropdown-align {
    margin-right: 12px;
}
.AccountSummaryDropDown .ant-select-multiple .ant-select-selection-overflow{
    // width:200px !important;
    flex-wrap: nowrap;
    // border: 1px solid red;
}
:where(.css-dev-only-do-not-override-a6vam3).ant-select-multiple.ant-select-show-arrow .ant-select-selector, :where(.css-dev-only-do-not-override-a6vam3).ant-select-multiple.ant-select-allow-clear .ant-select-selector {
         padding-inline-end: 0px;
         border-radius: var(--b-radius);
}

  .AccountSummaryDropDown {
    .ant-select-selection-overflow {
        flex-wrap: nowrap;
    }
  }