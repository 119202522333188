.dropdownContainers {
    display: flex;
    align-items: center;
    gap: 8px;
}

.dropdownContainers .dropdown-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    // padding-left: 12px;
    // padding-right: 8px;
    width: 100%;
    color: var(--text-main-color);
  }
 