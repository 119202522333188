.future-wac-column {
    border-left: 1px solid #cdd7df; 
    border-top:1px solid #cdd7df;
  }

  .wac-main-columns {
    border-top:1px solid #cdd7df
  }

  .wac-history-column {
    border: 1px solid #cdd7df; 
    
  }
  .wac-history-columns{
    border-top:1px solid #cdd7df
  }