.dropdown-tag {
  .tag-text {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  margin: 0px 2.5px;
  display: flex;
  flex-direction: row;
  padding: 4px 4px 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: var(--b-radius);
  border: 1px solid #00857c;
  background: #f7f7f7;
  color: #00857c;
  width: 60px;
  height: 20px;
}

.dropdown-tag .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector{
  padding: 0px;
}

.tag-count {
  color: #00857c;
}

.ant-select-single .ant-select-selector{
  font-size: 14px;
  color: var(--text-main-color);
  font-weight: 400;
}
