.contract-structure-header {
  background: #f3fcfe;
  display: grid;
  align-items: center;
  border-top: 1px solid #99cecb;
  border-bottom: 1px solid #99cecb;
  min-height: 40px;
  margin-top: 12px;
  padding: 16px;
  // display: flex;
}

.ant-collapse-item-active {
  .ant-collapse-header {
    background: #f1f1fd;
  }
}
.modal-dropdown {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 8px;
}
.modal-input {
  // max-width: 390px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  gap: 8px;
  // display: flex;
}
.ant-collapse {
  border-radius: var(--b-radius);
  background-color: #FFFFFF;
}
.cst-modal-wrapper{
  justify-content: space-between;
  background-color: white;
  padding-top: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
  padding-left: 16px;
  border: 1px solid #e1e9ef;
  border-bottom: none;
}
.collapse-icon {
  display: flex;
  column-gap: 24px;
}
.add-template-button {
  // margin-left: 12px;
  // margin-right: 13px;
  // margin-top: 10px;
  border-radius: var(--b-radius);
  background-color: #00857c;
  box-shadow: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  height: 40px;
}
.product-group-button {
  // margin-left: 12px;
  border-radius: var(--b-radius);
  border: 1px solid #00857c;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  height: 40px;
}
.collapse-main {
  margin: 16px;
  background-color: white;
  padding: 16px;
}
.collapse-main-header {
  min-height: 40px;
  // display: grid;
  // align-items: center;
  margin-bottom: 16px;
}

.modal-input-content-wrapper .ant-space-gap-col-small {
  column-gap: 8px;
  flex-wrap: wrap;
}
.ant-tabs-content .ant-tabs-content-top {
  background: #e1e9ef;
}
.contract-structure-dropdown {
  width: 145px;
}
.add-Contract-Modal .ant-modal-content {
  padding: 0;
  background: #f7f7f7;
  padding-bottom: 16px;
}
.contract-structure-dropdown .ant-select-single .ant-select-selector {
  border-radius: var(--b-radius);
  padding: 8px 12px;
}
.product-group-input {
  width: 280px;
  .ant-input{
    height: 40px;
    padding: 8px 12px;
    border-radius: 2px;
  }
}
.modal-input-wrapper {
  // padding-left: 18px;
  // width: 1282px;
  background: #E1E9EF;
  margin-bottom: 16px;
  // border: 1px solid #E1E9EF;
  border-width: 1px 1px 1px 12px;
  border-style: solid;
  border-color: #e1e9ef;
}
.cst-modal-wrapper .modal-dropdown .ant-select-selector{

  border-radius: var(--b-radius);
}
.modal-input-content-wrapper {
  min-height: 72px;
  display: grid;
  align-items: center;
  background-color: white;
  padding: 12px 4px;
  // max-height: 200;
}
.modal-input-content-criteria .ant-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:12px 4px;
}
// .product-modal .ant-modal-content {
//   padding: 0;
// }
.modal-input-content-criteria {
  // height: 58px;
  display: grid;
  align-items: center;
  background: #f6f7f7;
  padding: 0px 8px;
}
// .product-modal .ant-modal-header { 
//   padding: 16px;
//   height: 60px;
// }

.product-modal {
  .ant-modal-content {
    border-radius: var(--b-radius);
    padding: 0px;
  }

  .ant-modal-header {
    border-radius: var(--b-radius);
    display: flex;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 0px;
  }
  .ant-modal-title{
    color: #000000;
    font-weight: 700;
    line-height: 28px;
    font-size: 20px;
    font-style: normal;
  }

  .button-wrapper {
    display: flex;
  }

  .ant-modal-body{
    padding: 4px 0px 16px 0px ;
    background-color: #f7f7f7;
  }
}
.add-Contract-Modal .ant-modal-content .ant-modal-header {
  padding: 16px;
  margin-bottom: 16px;
}
.add-Contract-Modal .ant-modal-content .ant-modal-body {
  padding-right: 16px;
  padding-left: 16px;
  
}
.add-Contract-Modal .ant-modal-content .ant-modal-footer {
  //padding-top: 20px;
  padding-right: 16px;
  padding-bottom: 16px;
  background-color: white;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 0;
  border: 1px solid #e1e9ef;
  border-top: none;
}

.add-Contract-Modal .ant-modal-content .ant-modal-title{
  color: #000000;
    font-weight: 700;
    line-height: 28px;
    font-size: 20px;
    font-style: normal;
}
.ant-btn{
  font-size: 16px;
}
.ant-btn-primary{
  background-color: #00857c;
}
.product-div {
  padding: 16px;
  max-height: 429px;
  overflow: scroll;
  background-color: #ffffff;
}

.dropdown-text {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  // padding-left: 12px;
  color: var(--text-main-color);
}

.dropdown-text-criteria{
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  // padding-left: 12px;
  color:#55657A;
}
.download-button-contract{
  border: 1px solid #00857c;
  padding: 8px 16px;
  font-size: 16px;
  color: #00857c;
  border-radius: var(--b-radius);
  height: 40px;
}
.copy-button-icon-contract{
  border: 1px solid #00857c;
  padding: 0px 12px;
  font-size: 16px;
  color: #00857c;
  border-radius: var(--b-radius);
  margin-right: 10px;
}

.text-expandAll{
  color: #00857c;
  font-size: 16px;
}

.ant-input{
  height: 40px;
    padding: 8px 12px;
    border-radius: 2px;
}

.ant-input-number .ant-input-number-input{
  height: 40px;
  padding: 8px 12px;
  border-radius: 2px;
}

.ant-input-number{
  border-radius: 2px;
}

.ant-btn{
  height: 40px;
  padding: 4px 12px;
  border-radius: 2px;
}

.ant-btn-primary:disabled{
  border-radius: var(--b-radius);
  border: 1px solid #A7AEB4;
  color: #FFFFFF;
  background-color: var(--bgc-disabled);
}

.product-group-button.ant-btn-default:disabled{
  border-radius: var(--b-radius);
  border: 1px solid #00857c;
  color: #FFFFFF;
  background-color: #00857c;
}

.delete-modal{
  .ant-modal-content{
    padding: 0px;
    border-radius: 2px;
    background-color: #f6f7f7;
    padding-bottom: 16px;
    box-shadow: none;
    border: 1px solid #dadfdf;
  }

   .ant-modal-body{
    padding: 0px 16px;
   }

  .ant-modal-header{
    padding: 16px;
    margin-bottom: 16px;
    }

  .ant-modal-title{
    font-size: 20px;
    font-weight: 700;
  }

  .ant-modal-footer{
    background-color: #ffffff;
    padding-right: 16px;
    padding-bottom: 16px;
    margin: 0px 16px;
    border: 1px solid #e1e9ef;
  border-top: none;
  }
}

.ant-collapse-header-text{
  font-size: 14px;
  font-weight: 600;
}

