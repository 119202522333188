.create-scenario-table-main {
  // height: 74vh;
  background-color: #f6f7f7;
  padding: 11px 16px 16px 16px;
}
.align-scenario {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 0px 16px;
  height: 12vh;

  .scenario-header {
    font-size: 20px;
    font-weight: 700;
    color: var(--text-main-color);
    display: flex;
    align-items: center;
  }
  .align-create-scenario-copy {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.button-icon-scenario {
  border: 1px solid #00857c;
  padding: 0px 12px;
  font-size: 18px;
  color: #00857c;
  align-items: left;
  border-radius: var(--b-radius);
  height: 40px;
}

.no-hover:hover {
  outline: none;
}

.zero-border-radius {
  border-radius: var(--b-radius);
}

.scenario-action-modal-wrapper {
  justify-content: space-between;
  background-color: white;
  padding: 20px 16px 42px; /* Shortened shorthand */
  border: 1px solid #e1e9ef;
  border-bottom: none;
}

.scenario-status-modal-dropdown {
  max-width: 100%; /* Change to 100% */
  width: 100%; /* Add width 100% */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  margin-left: 10px; /* Add margin for spacing */
}

.scenario-modal-input {
  max-width: 100%; /* Change to 100% */
  width: 100%; /* Add width 100% */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}
.error-message {
  color: red; /* Set the color to red */
  font-weight: normal; /* Remove bold */
}

.edit-scenario-Modal .ant-input {
  border-radius: var(--b-radius); /* Set input box border radius to 0 */
}

.edit-scenario-Modal .ant-modal-content {
  padding: 0px;
  background-color: var(--bgc-popup);
  border-radius: var(--b-radius);
  padding-bottom: 16px;
}

.edit-scenario-Modal .ant-modal-content .ant-modal-header {
  padding: 20px 24px;
}
.edit-scenario-Modal .ant-modal-content .ant-modal-body {
  padding-right: 10px;
  padding-left: 10px;
  border-width: 1px 1px 0px 1px;
  border-color: #e1e9ef;
}
.edit-scenario-Modal .ant-modal-content .ant-modal-footer {
  padding-right: 12px;
  padding-bottom: 16px;
  background-color: white;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 0;
  border: 1px solid #e1e9ef;
  border-top: none;
}

.scenario-status-modal-dropdown .ant-select-selector {
  border-radius: var(--b-radius); /* Set dropdown border radius to 0 */
}

.footer-container {
  height: 38px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: var(--footer-bgc-color);
  padding: 8px 16px;
  z-index: 999; /* Ensure the footer stays on top of other elements */
  justify-content: flex-end; /* Align content to the right */
  gap: 10px;
}

.footer-container .footer-col {
  display: flex;
  align-items: center; /* Align items vertically */
  justify-content: flex-end;
  height: 22px;
  width: 262px;
}

.footer-container .ant-btn {
  color: #00857c; /* Set link color */
  padding: 0; /* Remove default padding */
  margin-left: 10px; /* Add space between links */
}

.footer-container .ant-btn:last-child {
  margin-left: 0; /* Remove margin for the last link */
}

.footer-container .vertical-line {
  border-left: 1px solid var(--text-button); /* Add vertical line */
  height: 15px; /* Adjust height to match the links */
  margin-left: 10px; /* Add space between links and line */
  margin-right: 10px; /* Add space after the line */
}

.table-container {
  // max-height: 615px; /* Adjust the maximum height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 0px 16px;
  background-color: #fff;
}

// .table-container::-webkit-scrollbar{
//   display: none;
// }

.table-container .ant-table-thead {
  position: sticky;
  top: 0;
  background-color: #fff; /* Add background color for the fixed header */
  z-index: 999; /* Ensure the header stays above other elements */
}

.table-container .ant-table-body {
  overflow-y: auto; /* Enable vertical scrolling for the table body */
  max-height: 300px; /* Adjust the maximum height of the table body */
}

.table-container .ant-table-cell {
  white-space: nowrap; /* Prevent text wrapping in table cells */
}

// .main-table-container:where(.css-dev-only-do-not-override-a6vam3).ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >tbody>tr>td{
//   border-inline-end: none;
// }

.table-container .reusable-grid .ant-table-wrapper .ant-table.ant-table-small .ant-table-filter-trigger{
  margin-inline-end: 300px;
}

.table-container .reusable-grid .ant-table-wrapper .ant-table-column-sorters {
  display: inline-flex;
}


.custom-tooltip .ant-tooltip-inner {
  background-color: #ffffff !important; 
  color: #00857c !important;            
  font-weight: 600;                    
  font-size: 14px;
  border: 1px solid #00857c;                     
}
