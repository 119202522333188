.CreateScenerioHeader {
  height: 70px;
  background: #f3fcfe;
  border-top: 1px solid #99cecb;
  border-bottom: 1px solid #99cecb;
  align-items: center;
  padding: 16px;
}
.dropdown-tag {
  .tag-text {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  margin: 0px 2.5px;
  display: flex;
  flex-direction: row;
  padding: 4px 4px 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: var(--b-radius);
  border: 1px solid #00857c;
  background: #f7f7f7;
  color: #00857c;
  width: 60px;
  height: 20px;
}

.tag-count {
  color: #00857c;
}

.column-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items horizontally to the left */
  // margin-left: 12px;
}

.dropdown-text-scenario {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0;
  padding: 0;
  padding-bottom: 8px;
}

// .ant-row {
//   display: flex;
//   align-items: center; /* Align items vertically */
// }

// .ant-col {
//   display: flex;
//   align-items: center; /* Align items vertically */
// }

.create-scenario-buttons {
  border-radius: var(--b-radius);
  box-shadow: none;
  // margin-right: 10px;
  // margin-bottom: 24px;
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 24px;
  color: #FFFFFF;
  background-color: #00857c;
}

.create-scenario-buttons-disabled-left{
  border-radius: var(--b-radius);
  box-shadow: none;
  // margin-right: 10px ;
  // margin-bottom: 8px;
}

.create-scenario-buttons-disabled-right{
  border-radius: var(--b-radius);
  box-shadow: none;
  // margin-right: 10px ;
}

.create-scenario-buttons-disabled-right .ant-btn-primary:disabled, .ant-btn-primary.ant-btn-disabled{
  border-radius: var(--b-radius);
  border: 1px solid #A7AEB4;
  color: #FFFFFF;
  background-color: var(--bgc-disabled);
}



.create-scenario-buttons-disabled-left .ant-btn-default:disabled, .ant-btn-default.ant-btn-disabled{
  border-radius: var(--b-radius);
  border: 1px solid #A7AEB4;
  color: #FFFFFF;
  background-color: var(--bgc-disabled);
}



.scenario-name-input {
  max-width: 376px; /* Change to 100% */
  font-size: 14px;
  font-style: normal;
  border-radius: var(--b-radius);
  width: calc(90% * 2)
  
}

.scenario-historical-date-selection{
  max-width: 376px; /* Change to 100% */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  border-radius: var(--b-radius);
  width: calc(65% * 2);
  padding: 8px 12px;
  height: 40px;
}

.create-scenario-action-modal-wrapper {
  background-color: white;
}
.create-scenario-action-modal-wrapper-header {
  padding: 16px;
}

.create-scenario-status-modal-dropdown {
  max-width: 100%; /* Change to 100% */
  width: 240px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  // margin-right: 16px; /* Add margin for spacing between dropdowns */
  // padding:10px 0px
}

.create-scenario-status-modal-dropdown .ant-select-selector {
  border-radius: var(--b-radius); /* Set dropdown border radius to 0 */
}

.manually-create-button {
  display: block;
  margin-left: auto; /* Ensure the button is pushed to the right */
  margin-top: 20px;
  border-radius: var(--b-radius);
  border: 1px solid #008575;
  color: #008575;
}

.create-scenario-buttons-cancel{
  display: block;
  margin-left: auto; /* Ensure the button is pushed to the right */
  border-radius: var(--b-radius);
  border: 1px solid #008575;
  color: #008575;
}

.manually-create-button .ant-btn-default:disabled, .ant-btn-default.ant-btn-disabled{
  border-radius: var(--b-radius);
  border: 1px solid #A7AEB4;
  color: #FFFFFF;
  background-color: var(--bgc-disabled);
}


.create-scenario-contract-dropdown,
.create-scenario-account-dropdown {
  min-height: 40px; /* Adjust the height as needed */
}

.create-scenario-custom-modal {
  min-width: 1168px;
  height: 90vh;
  .modal-content-wrapper {
    overflow-y: auto; /* Enable vertical scrolling if needed */
    height: calc(90vh - 120px); /* Adjust maximum height as needed */
    background-color: #ffffff;
    // border: 1px solid red;
  }
}

.create-scenario-custom-modal .ant-modal-content{
  border-radius: var(--b-radius);
  padding:0px;
  background-color: #f7f7f7;
  padding-bottom: 16px;
}

.create-scenario-custom-modal .ant-modal-title{
  color: #000000;
  font-weight: 700;
  line-height: 28px;
  font-size: 20px;
  font-style: normal;
}

.create-scenario-custom-modal .ant-modal-header {
  border-radius: var(--b-radius);
  display: flex;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 0px;
  height: 60px;
}


.create-scenario-custom-modal .ant-modal-body{
  padding: 16px 16px 0px 16px;
}

.create-scenario-custom-modal .ant-modal-footer{
  padding:16px;
  background-color: white;
  margin-top: 0px;
  margin-left: 16px;
  margin-right: 16px;
}


.create-scenario-modal-Two{
  width: 340px;
  height: 40px;
}

.create-scenario-modal-Two .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector{
  border-radius: var(--b-radius);
  padding: 8px 12px;
}

.create-scenario-modal-dropdown-one{
width:250px;
border-radius:var(--b-radius);
}

.ant-btn-primary{
  box-shadow: none;
}

.create-scenario-modal-Two .ant-select-selection-search {
  display: flex;
  flex: 1;
  max-width: 160px; 
}

.create-scenario-modal-Two .ant-select-selection-search-input {
  width: 60%;
  // text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.create-scenario-modal-Two .ant-select-selection-item {
  max-width: 100px; 
  overflow: hidden;
  // text-overflow: ellipsis;
  white-space: nowrap;
}

.create-scenario-modal-Two .ant-select-selection-overflow {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  // width: 155px;
}

.create-scenario-modal-Two .custom-search-input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 5px;
}





