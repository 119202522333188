.accountSummaryContainer {
    background: #F6F7F7;
}

.accountSummary-container-firstTab {
  position: sticky;
    border: 1px solid #99CECB;
    // border-bottom: 1px solid #99CECB;
    background: #F3FCFE;
    display: flex;
    min-height:38px;
    padding: 16px;
    gap: 10px;
  }

  .accountSummaryContainer-GroupButton {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 16px;
    // margin-right: 12px;
  }

  
.accountSummary-container-Chart1st {
  // border: 1px solid #99CECB;
  // border-bottom: 1px solid #99CECB;
  background: #F6F7F7;
  display: flex;
}

.accountSummary-container-Chart1st .ant-card-bordered{
  border: none;
}

.accountSummary-container-Chart1st .ant-card .ant-card-body {
  padding: 0px !important;
  // background-color: greenyellow;
}

.Graphcontainer {
  display: block;
    flex-direction: column;
    align-items: flex-start;
}

.Graphcontainer .paraTitile {
  font-weight: 700;
  font-size: 16px;
}
.Graphcontainer .PPDContainer {
  display: flex;
  justify-content: space-between;
}

.contract-financial-card .ant-card .ant-card-body{
  padding: 0px 0px 24px  0px !important;
}


.summary-table-one .reusable-grid .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >thead>:nth-child(1){
  // table >colgroup {
    background-color: #99CECB !important;
  // }
}

.ant-card-custom-head .ant-card-head{
  padding:0px;
  border-radius:var(--b-radius);
}


// <style>{`.ant-table-header table> thead>:nth-child(1)>:nth-child(2) {background-color: #ff4d4f !important}`}</style>
// .summary-table-one table>thead>:nth-child(1)>th {
//   background-color: white !important;
// }
.summary-table-one table>thead>:nth-child(1)>th {
  background-color: white !important;
  border-inline-end: 2px solid #CDD7DF
}

.summary-table-one .reusable-grid .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:first-child > th:first-child{
  // padding: 0px;
  border-right: 1px solid #CDD7DF
}
.summary-table-one .reusable-grid .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td:first-child{
  // padding: 0px;
  border-right: 1px solid #CDD7DF
}

.summary-table-one .ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
  border: none;
  height: 0px;
}

.summary-table-one:where(.css-dev-only-do-not-override-a6vam3).ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >tbody>tr>td{
  border-inline-end: 1px solid #eef0f2;
}

.summary-table-one .ant-table-tbody >tr >td {
border-bottom: 1x solid #CDD7DF
}

.summary-table-one{
  padding:0px 12px;
}

.as-parent-column{
  align-content: end;
}

.ass-parent-column{
  border-left: 1px solid #cdd7df; 
}