.contractHeader-count{
    display: flex;
    align-items: center;
    padding: 4px 8px;
    gap: 10px;
}

.contractHeader-count .contractHeader-text{
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color:#37424A
}

.contractComparison-Divider{
    border-width: 1.6;
    border-color: silver;
    height: 22px;
    margin: 0px 16px;
}