.Contract-modal-dropdown-3 {
  .ant-select-selector {
    height: 36px !important; // Adjust height
    width: 500px !important; // Adjust width
    display: flex;
    align-items: center;
    border-radius: var(--b-radius);
  }
}
// .contract-comparison-collapse .ant-collapse-content-box .reusable-grid{
//   width: 1162px
// }

.contract-comparison-modal {
  width: 1491px !important;
  .ant-modal-content {
    border-radius: var(--b-radius);
    padding: 0px;
    background-color: #f6f7f7;
    padding-bottom: 16px;
  }
  .ant-modal-body{
    margin: 16px 16px 0px 16px;
    background-color: #ffffff;
    padding: 0px;
    
  }

  .ant-modal-header {
    border-radius: var(--b-radius);
    display: flex;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 0px;
  }
  .ant-modal-title{
    color: #000000;
    font-weight: 700;
    line-height: 28px;
    font-size: 20px;
    font-style: normal;
  }

  .ant-modal-footer{
    margin-top: 0px;
  }
}

.Define-Contract-Template {
  .ant-modal-content {
    border-radius: var(--b-radius);
    padding:0px;
    background-color: #f6f7f7;
    padding-bottom: 16px;
  }

  .ant-modal-body{
    margin: 16px 16px 0px 16px;
    background-color: #ffffff;
  }

  .ant-modal-header {
    border-radius: var(--b-radius);
    display: flex;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 0px;
  }
  .ant-modal-title{
    color: #000000;
    font-weight: 700;
    line-height: 28px;
    font-size: 20px;
    font-style: normal;
  }

  .ant-modal-footer{
    margin: 0px 16px 0px 16px;
    background-color: #ffffff;
  }

  .button-wrapper {
    display: flex;
  }
}

.define-Wac-Modal-Table .ant-select-arrow{
  inset-inline-end: -340px;
}


.contract-template-header {
  background: #f3fcfe;
  display: flex;
  align-items: center;
  border-top: 1px solid #99cecb;
  border-bottom: 1px solid #99cecb;
  min-height: 40px;
  // margin-top: 12px;
  padding: 16px;
}