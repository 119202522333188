.faq-modal {
    .faq-container {
      display: flex;
      height: 100%; 
    }
  
    .faq-sidebar {
      width: 250px;
      border-right: 1px solid #ddd;
      padding-right: 10px;
      display: flex;
      flex-direction: column;
      
      button {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        text-align: left;
        padding: 10px 15px;
        border: 1px solid #D3D6DB;
        background: none;
        font-weight: bold;
        color: #555;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
  
        // &:hover {
        //   background-color: #00857C !important;
        //   color: #ffffff !important;
        // }
        
        &.active {
          color: #ffffff;
          background-color: #00857C;
        }
  
        &:focus {
          outline: none;
        }
      }
    }
  
    .faq-content {
      flex: 1;
      padding: 8px 16px;
      overflow-y: auto; 
  
      h2 {
        font-size: 24px;
        margin-bottom: 15px;
      }
  
      p {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 10px;
      }

      .ant-collapse-header {
        font-weight: bold;
        font-size: 18px;
      }
  
      .ant-collapse-content-box {
        font-size: 16px;
        padding-left: 24px;
      }
    }
  }
  
  /* Additional media query for responsiveness */
  @media (max-width: 768px) {
    .faq-modal {
      .faq-container {
        flex-direction: column;
      }
  
      .faq-sidebar {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid grey;
        padding-right: 0;
        flex-direction: row;
        justify-content: space-around;
  
        button {
          padding: 10px 5px;
          font-size: 14px;
        }
      }
  
      .faq-content {
        padding: 15px;
      }
    }
  }

  .faq-modal .ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
     border-radius: 0px;
    //  background-color: #D3D6DB;
     
}

.faq-modal .ant-modal-content {
border-radius: 0px;
border-color: #D3D6DB;
border-width: 1px 1px 1px 10px;
border-style: solid;
padding: 12px 24px;
}


.faq-content .ant-collapse-item {
  margin-bottom: 20px;  
  border: 1px solid #e8e8e8;  
  border-radius: 4px;  
  background-color: #D3D6DB;  
  padding: 5px; 
}

.faq-content .ant-collapse-item:last-child {
  margin-bottom: 0;
  border-radius: 4px;
}
  

// .faq-modal {
//     .faq-container {
//       display: flex;
//       height: 100%; 
//     }
  
//     .faq-sidebar {
//       width: 250px; 
//       border-right: 1px solid #ddd;
//       padding-right: 15px; 
//       padding-left: 15px;
//       display: flex;
//       flex-direction: column;
//       background-color: #ffffff; 
  
//       button {
//         display: block;
//         width: 100%;
//         text-align: left;
//         padding: 10px 20px;
//         border: none;
//         background: none;
//         font-weight: bold;
//         color: #000000; 
//         cursor: pointer;
//         transition: background-color 0.3s, color 0.3s;
//         border: 1px solid grey;
//         &:hover {
//           background-color: #00857c; 
//         }
  
//         &.active {
//           color: #00796B; 
//           background-color: #E0F2F1; 
//         }
  
//         &:focus {
//           outline: none;
//         }
//       }
//     }
  
//     .faq-content {
//       flex: 1;
//       padding: 20px;
//       overflow-y: auto; 
//       background-color: #FAFAFA;
  
//       h2 {
//         font-size: 24px;
//         margin-bottom: 15px;
//         color: #00796B; 
//       }
  
//       p {
//         font-size: 16px;
//         line-height: 1.5;
//         margin-bottom: 10px;
//         color: #333; 
//       }
  
//       .ant-collapse-header {
//         font-weight: bold;
//         font-size: 18px;
//         color: #00796B; 
//         background-color: #E0F7FA; 
//       }
  
//       .ant-collapse-content-box {
//         font-size: 16px;
//         padding-left: 24px;
//         background-color: #FAFAFA; 
//       }
//     }
//   }
  
//   @media (max-width: 768px) {
//     .faq-modal {
//       .faq-container {
//         flex-direction: column;
//       }
  
//       .faq-sidebar {
//         width: 100%;
//         border-right: none;
//         border-bottom: 1px solid #ddd;
//         padding-right: 0;
//         padding-left: 0;
//         flex-direction: row;
//         justify-content: space-around;
//         background-color: #ffffff;
  
//         button {
//           padding: 10px 5px;
//           font-size: 14px;
//         }
//       }
  
//       .faq-content {
//         padding: 15px;
//       }
//     }
//   }
  