.notes-header {
  background-color: #f3fcfe;
  display: grid;
  align-items: center;
  border-top: 1px solid #99cecb;
  border-bottom: 1px solid #99cecb;
  height: 40px;
  padding: 16px;
}

.notes-section {
  background-color: #f6f7f7;
  height: 100vh;

  .col-notes-section {
    display: flex;
    gap: 16px;
    width: 100%;
    padding: 16px;

    .notes-section-1 {
      width: 50%;
      border: 1px solid #e1e9ef;
      background-color: #ffffff;
      padding: 0px 16px 16px 16px;
      height: auto;

      .note-section-1-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0px;

        .Text-note {
          font-family: Open Sans;
          font-size: 20px;
          font-weight: 700;
          color: #37424a;
        }

        .button-note {
          border: 1px solid #00857c;
          padding: 0px 10px;
          font-size: 18px;
          color: #00857c;
          border-radius: var(--b-radius);
          margin-left: 8px;
        }
        .add-button-note {
          padding: 8px 24px;
          height: 40px;
          border: 0px;
          font-weight: 400;
          color: #00857c;
          font-size: 16px;
        }

        .toolTip {
          background: #ffffff;
          color: #00857c;
        }
        .textarea-container {
          display: flex;
          flex-direction: column;
        }

        .ant-input {
          max-width: 100%;
          max-height: 150px;
          overflow: auto;
        }
      }
    }

    .attachment-section-2 {
      width: 50%;
      border: 1px solid #e1e9ef;
      background-color: #ffffff;
      padding: 0px 16px 16px 16px;
      height: auto;
      overflow: hidden;

      .attachment-section-2-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0px;
        .Text-note {
          font-family: Open Sans;
          font-size: 20px;
          font-weight: 700;
          color: #37424a;
        }

        .button-note {
          padding: 8px 24px;
          height: 40px;
          border: 0px;
          font-weight: 400;
          color: #00857c;
        }
      }

      .attachment-list {
        max-height: 120px;
        min-height: 156px;
        // overflow-y: auto;
        // border: 1px solid #e8e8e8;
        padding: 8px;
        // background-color: #f9f9f9;
        .attachment-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid silver;
          padding: 0px 10px;
          color: #00857c;
          border-radius: 5px;
          margin-bottom: 8px;
          
            .button-note {
              height: 40px;
              border: 0px;
              font-weight: 400;
              color: #00857c;
            }
          
        }
        .attachment-item:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.button-container {
  display: flex;
  justify-content: flex-end;
  padding: 16px 0px 0px 0px;
}

.ant-input
  .css-dev-only-do-not-override-a6vam3
  .ant-input-outlined
  .textarea-container {
  max-height: 30px;
}

.attachment-actions {
  display: flex;
  justify-content: space-between;
  gap:20px;
  border: 1px solid red;
}

.notes-date-text{
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0px 8px;
  .created-date{
    padding: 0px 8px;
    font-size: 16px;
    font-weight: 700;
  }
}

.ant-btn-default{
  // border:none;
  box-shadow: none;
  // border-color: #00857C;
}
