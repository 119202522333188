.header-tab-group {
  .ant-tabs-nav {
    padding: 0px 36px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);
    height: 48px;
    margin-bottom: 5px;
  }
  .ant-tabs-ink-bar {
    background: #00857c;
  }

  .header-tab-header-active {
    font-size: 16px;
    font-weight: 600;
    color: #00857c;
  }
  .header-tab-header {
    font-size: 16px;
    font-weight: 400;
    color: #37424A;
  }
}
