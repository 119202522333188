.currAlignment-align {
  background: var(--bgc-main-header);
  display: grid;
  align-items: center;
  border-top:  var(--border-main);
  border-bottom: var(--border-main);
  min-height: 40px;
  padding: 16px;
  max-height: 100px;
}

.save-button-align-one {
  border: 1px solid #00857c;
  background-color: #00857c;
  color: #ffffff;
  border-radius: var(--b-radius);
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 8px 16px;
}

.download-button-align{
  border: 1px solid #00857c;
  color: #00857c;
  // margin-right: 14px;
  border-radius: var(--b-radius);
  height: 40px;
  padding:8px 16px ;
}
.alignment-table-main-align {
  margin: 16px;
  background-color: white;
  padding:16px;
  border: 1px solid #E1E9EF;
  border-radius: var(--b-radius);
  .alignment-table-main-header-align {
    display: grid;
    align-items: center;
    padding-bottom: 16px;

  }
}

.currAlignment-Dropdown-align {
  width: 150px;
}
.Dropdown-text-align {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}

.dropdown-align {
  min-width: 120px !important;
  width: 217px !important;
  // padding: 0px 10px;
  .ant-select-selector {
    border-radius: var(--b-radius);
  }
}


.dropdown-align-base {
  width: 200px !important;
  // padding: 0px 10px;
  .ant-select-selector {
    border-radius: var(--b-radius);
  }
}
.grid-dropdown-align {
  width: 165px !important;
  .ant-select-selector {
    border-radius: 2px  0px 0px 2px;
    color: #37424a;;
    border: 1px solid #d3d6db !important;
  }
}
.grid-column-header-align {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #37424a;
  // margin-right: 20px;
}
.multi-dropdown-align {
  width: 420px !important;
  padding: 0px;
  .ant-select-selector {
    border-radius: var(--b-radius);
  }
}


.template-save-button-align {
  border: 1px solid #00857c;
  border-radius: var(--b-radius);
  color: #454250;
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 8px 16px;
}

.add-alignment-button-align {
  border: 1px solid #00857c;
  border-radius: var(--b-radius);
  color: #454250;
  margin-right: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 8px 16px;
}
.edit-button-icon-align {
  border-color:#d3d6db ;
  border-width: 1px 1px 1px 0px;
  color: #00857c;
  background: #ffffff;
  border-radius:  0px  2px 2px 0px;
  padding: 0px 13px;
  height: 40px;
}

.button-icon-align {
  border: 1px solid #00857c;
  padding: 8px 16px;
  font-size: 16px;
  color: #00857c;
  border-radius: var(--b-radius);
  // margin-left: 8px;
  height: 40px;
}
.alignment-align-one {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  // padding-left: 12px;
  color: #37424A;
}
.alignment-align-one-1 {
  display: flex;
}

.current-alignment-divider{
  border:1px solid silver;
  height: 32px;
}



.alignment-align-two {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  margin-right: 10px;
  color: #37424A;
}
.alignment-align-three {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  margin-left: 10px;
  color: #37424A;
}
.grid-column-header-container-align{
  display: flex;
  width: fit-content;
  align-items: center;
  // margin-left: 10px;
}



.aliments-main-header-dropdown{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    gap:10px;
    // margin: 8px 0px 10px 0px ;
}

// .grid-column-header-container-align {
  .ant-modal-root .ant-modal-mask {
      background-color: unset !important;
  }
// }

.Current-Alignment-table{
  width: 220
}

.current-alignment-editIcon{
  margin-left: 2px;
  margin-right: 2px;
  width: 20px;
  height: 20px;
  padding: 0px;
}
.current-alignment-btnn .ant-btn{
  padding: 0px 12px;
}


.current-alignment-editIcon-one{
  margin-left: 2px;
  margin-right: 2px;
  width: 20px;
  height: 20px;
}

.current-alignment-errorAlert{
  margin-right: auto;
  z-index: 100;
  position: fixed;
  left: 40%;
}

.aliments-main-header-dropdown-one{
  display: flex;
  flex-wrap: wrap;
}

// .grid-column-header-container-align {
  .ant-modal-root .ant-modal-mask {
      background-color: unset !important;
  }
// }


// .add-alignment-input:where(.css-dev-only-do-not-override-a6vam3).ant-input{
//   width: 65%;
// }



.ca-table .reusable-grid .ant-table-wrapper .ant-table.ant-table-small .ant-table-filter-trigger{
  margin-inline-end: 120px;
}

.ca-table .reusable-grid .ant-table-wrapper .ant-table-column-sorters {
  display: inline-flex;
}

.ca-table .reusable-grid .ant-table-wrapper .ant-table-column-title{
  width: max-content;
}

.ca-columns{
 border-top:1px solid #cdd7df;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}

