body {
  margin: 0;
  padding:0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  

}



body::-webkit-scrollbar{
  display: none;
}


:root{
  --bgc-disabled:#D3D6DB;
  --border-disabled:#A7AEB4;
  --text-disbaled:#FFFFFF;
  --text-main-color:#37424A;
  --color-border-top:#D3D6DB;
  --color-border-inner:#D9D9D9;
  --color-active-border:#00857C;
  
  --b-radius:2px;
  --bgc-table-head:#E6E7E9;
  --table-border:#CDD7DF;
  --bgc-main-header:#f3fcfe;
  --border-main:1px solid #99CECB;
  --text-font-weight-main:700;
  --text-font-weight-sub:400;
  --box-height:70px;


  --bgc-button:#00857C;
  --border-button:#00857C;
  --text-button:#454250;
  --footer-bgc-color:#E6E7E9;
  --bgc-popup:#F6F7F7;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
