/* RfpApprovalOutputs.css */
.rfp-approval-outputs {
  padding: 20px;
}

.data-box {
  padding: 0px 12px;
  width: 100%;
}

.data-box .title {
  font-size: 16px;
  font-weight: 600;
  color: #55657a;
  padding: 8px;
}

.data-box .subtitle {
  font-size: 12px;
  color: #8c8c8c;
}

.data-box-items {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 15px;
}

.account-summary-cards .ant-card-head {
  padding: 10px 16px;
}

.account-summary-cards .ant-card-body{
  padding: 12px 16px 16px 16px;
}

.account-summary-cards .ant-card-head-title {
  font-size: 20px;
  font-weight: 700;
  color: var(--text-main-color);
}

.ant-card-bordered {
  border-radius: var(--b-radius);
  border: 1px solid #CDD7DF;
}

.dropdown-align-ac-cards{
  min-width: 130px !important;
  // width: 217px !important;
  // padding: 0px 10px;
  .ant-select-selector {
    border-radius: var(--b-radius);
  }
}

