.edit-sites-volume-custom-modal {
  min-width: 1168px;
  height: 90vh;

  .modal-content-wrapper {
    // overflow-y: auto; /* Enable vertical scrolling if needed */
    height: calc(90vh - 120px); /* Adjust maximum height as needed */
  }
}

.add-party-id-modal {
  .CreateScenerioHeader {
    height: 98px;
    background: #f3fcfe;
  }
}

.edit-sites-volume-custom-modal .ant-modal-content{
  border-radius: var(--b-radius);
  padding:0px;
  background-color: #f7f7f7;
  padding-bottom: 16px;

}

.edit-sites-volume-custom-modal .ant-modal-title{
  color: #000000;
    font-weight: 700;
    line-height: 28px;
    font-size: 20px;
    font-style: normal;
}
.edit-sites-volume-custom-modal .ant-modal-body{
  padding: 16px 16px 0px 16px;
}
.edit-sites-volume-custom-modal .ant-modal-footer{
  padding: 16px;
  background-color: white;
  margin-top: 0px;
  margin-left: 16px;
  margin-right: 16px;
}
.edit-sites-volume-custom-modal .ant-modal-header{
  border-radius: var(--b-radius);
    display: flex;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 0px;
    height: 60px;
}
  .input-error {
    border-color: red !important;
  }
  
  .error-message {
    color: red;
    margin-top: 5px;
    font-size: 12px;
  }

  .customVolumes .reusable-grid .ant-table-wrapper .ant-table.ant-table-small .ant-table-filter-trigger{
    margin-inline-end: 400px;
  }

  .customAccountSites .reusable-grid .ant-table-wrapper .ant-table.ant-table-small .ant-table-filter-trigger{
    margin-inline-end: 400px;
  }

