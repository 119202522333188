.Scenerio-input-layout {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .ant-tabs-nav {
    box-shadow: none !important;
    margin-bottom: 0 !important;
  }
  .Scenerio-input-header {
    font-size: 20px;
    font-weight: 700;
    padding-left: 20px;
  }
  .Scenerio-input-tab-group {
    .ant-tabs-ink-bar {
      background: #00857c;
    }
    .Scenerio-input-tab-header-active {
      font-size: 16px;
      font-weight: 600;
      color: #00857c;
    }
    .Scenerio-input-tab-header {
      font-size: 16px;
      font-weight: var(--text-font-weight-sub);
      color: var(--text-main-color);
    }
  }
}

.header-nav-Scenerio {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 0px 16px;

  .sub-header-nav-Scenerio-1 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    
    .Heading-Scenerio {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      margin: 6px 12px;
    }
    .button-icon-Back{
        font-size: 18px;
        color: #00857c;
        height:40px;
        padding: 8px 16px;
        width: 32px;
        border: 0px;
    }
  }
  .sub-header-nav-Scenerio-2 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  
    .date-text-Scenerio {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #737373;
      // margin-right: 8px;
    }
   
    .user-icon-Scenerio {
      margin-right: 10px;
    }

    .profile-details-Scenerio {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #37424a;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      margin-left: auto;
      gap:8px;
      
      .button-icon-Scenerio{
        border: 1px solid #00857c;
        font-size: 16px;
        color: #00857c;
        border-radius: var(--b-radius);
        height:40px;
        // margin-right:8px;
        // padding: 8px 16px;
        width: 48px;
      }
      .save-button-Scenerio {
        border: 1px solid #00857c;
        background-color: #00857c;
        color: #ffffff;
        height:40px;
        // padding: 8px 16px;
        border-radius: var(--b-radius);
        width: 67px;
        margin: 0px;
      }
    }
  }
}



