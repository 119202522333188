.currentAlignment-History-custom-modal {
  .ant-modal-content {
    border-radius: var(--b-radius);
    padding: 0px;
    background-color: #f6f7f7;
  }

  .ant-modal-header {
    border-radius: var(--b-radius);
    display: flex;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 0px;
  }
  .ant-modal-title{
    color: #000000;
    font-weight: 700;
    line-height: 28px;
    font-size: 20px;
    font-style: normal;
  }

  .button-wrapper {
    display: flex;
  }
  }
  
  .ac-history-header {
    min-height: 42px;
    background: #F3FCFE;
    padding: 16px;
    border-top: 1px solid #99cecb;
    border-bottom: 1px solid #99cecb;
  }
  
  .ac-history-header .ant-row {
    display: flex;
    align-items: center;
    // padding-top: 10px;
  }
  
  .align-right {
    justify-content: flex-end;
    // margin-right: 15px;
  }
  
  .currentHistory-button-icon {
    border: 1px solid #00857c;
    padding: 8px 16px;
    font-size: 16px;
    color: #00857c;
    border-radius: var(--b-radius);
    height: 40px;
  }
  .contract-structure-history-Table{
    padding: 16px;
    background-color: white;
  }

  .contract-structure-history-main{
    padding: 16px;
    // background-color: white;
  }
  
  .currentAlignment-history {
    background-color: #f6f7f7;
    padding: 16px 16px 16px 16px;
  }
  
  
  .contract-structure-history-Table .reusable-grid .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-header >table >thead>tr>th
    {
      background-color: #E6E7E9;
      border-inline-end:none;
      padding: 12px;
      border-start-end-radius: initial;
      border-start-start-radius:initial;
      border-radius : var(--b-radius);
    }

    .contract-structure-history-Table .reusable-grid .ant-table-wrapper .ant-table.ant-table-small .ant-table-filter-trigger{
      margin-inline-end: 200px;
    }
    
    .contract-structure-history-Table .reusable-grid .ant-table-wrapper .ant-table-column-sorters {
      display: inline-flex;
    }
    
    .contract-structure-history-Table .reusable-grid .ant-table-wrapper .ant-table-column-title{
      width: max-content;
    }
  