// Header

.dropdownContainers-POV {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dropdownContainers-POV .dropdown-text-POV {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  // padding-left: 12px;
  // padding-right: 8px;
  width: 100%;
  color: var(--text-main-color);
  
}

.dropdownContainers-POV-1 {
    display: flex;
    align-items: center;
  }

  .dropdownContainers-POV-1 .dropdown-text-POV-1 {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    padding-left: 12px;
    padding-right: 8px;
    // width: 100%;
    color: var(--text-main-color);
  }

  .dropdownContainers-POV-2 {
    display: flex;
    align-items: center;
  }

  .dropdownContainers-POV-2 .dropdown-text-POV-2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    // padding-left: 12px;
    // padding-right: 8px;
    // width: 100%;
    color: var(--text-main-color);
  }

  .dropdownContainers-POV-2 .dropdown-text-bottom-POV-2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A7AEB4;
  }

  .button-customerpov{
    border:1px solid #00857C;
    width: 152px;
    height: 40px;
    border-radius: var(--b-radius);
    // padding: 8px 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    color: #454250;
    // margin-left: 12px;
  }





  .dropdown-align-POV {
    min-width: 120px !important;
    width: 240px !important;
    // border: 1px solid #D3D6DB;
    border-radius: var(--b-radius);
    .ant-select-selector {
      border-radius: var(--b-radius);
      color:#37424A
    }
  }

  .dropdown-align-POV-One {
    // min-width: 120px !important;
    // width: 173px !important;
    // border: 1px solid #D3D6DB;
    border-radius: var(--b-radius);
    .ant-select-selector {
      border-radius: var(--b-radius);
      color:#37424A
    }
  }

//  Component css

.customerPOV-main-container {
  background: #f6f7f7;
}

.customer-POV-Header-One {
  background: #f3fcfe;
  display: flex;
  align-items: center;
  border-top: 1px solid #99cecb;
  border-bottom: 1px solid #99cecb;
  height: 40px;
  padding: 16px;
}
.container_table_column_alignment .reusable-grid {
  align-self: flex-start;
  width: 100%;
}
.container_table_column_alignment{
  flex: 1;
  min-width: 0;
  width: 60%;
}


.container_table_column_alignment_one {
  align-self: flex-start;
  padding-left: 8px;
  flex: 1;
  min-width: 0;
  width: 40%;
}

.container_table_column_alignment_one .reusable-grid .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >thead>tr>th
  {
    background-color: #E1F1FD;
    border-inline-end:none;
    padding: 12px;
    border-start-end-radius: initial;
    border-start-start-radius:initial
    // border-radius: 0;
  }

// .container_table_column_alignment .ant-table-cell .editable-cell-value-wrap {
//   border: none;
//   padding: 0px;
//   border-radius: 0px;
// }

.container_table_column_alignment_one .reusable-grid .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content{
  border: 1px solid #96CDF9; /* Change the border color here */
}

// .main-tables .ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
//   border: none
// }

// .main-tables .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >tbody>tr>td{
//   border-inline-end: none;
// }

.main-tables{
  display: flex;
  flex-wrap: nowrap;
}

@media screen and (max-width: 1023px) {
  .main-tables{
    flex-direction: column;
  }
 .container_table_column_alignment{
  width: 100%;
 }
 .container_table_column_alignment_one{
  width: 100%;
 }

}


.container_table_column_alignment_one .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >tbody>tr>td{
  font-size: 14px;
  font-weight: 700;
  color: #37424A;
  height: 78px;
  // min-width: 129px;
 
}

// .container_table_column_alignment .ant-table-cell {
//   .editable-cell-value-wrap {
//     color: red;
//     ;
//   }
// }

.main-tables .ant-table-tbody >tr >td {
  border-bottom: 1x solid #CDD7DF;
  min-width: 100px;
  }


  // .container_table_column_alignment .reusable-grid .ant-table-wrapper .ant-table.ant-table-small .ant-table-filter-trigger{
  //   margin-inline-end: 120px;
  // }

  .container_table_column_alignment .reusable-grid .ant-table-wrapper .ant-table-column-sorters {
    display: inline-flex;
  }

  

  .container_table_column_alignment_one .reusable-grid .ant-table-wrapper .ant-table-column-sorters {
    display: inline-flex;
  }


  // .container-flex {
  //   display: flex;
  //   flex-wrap: nowrap;
  //   align-items: center;
  //   justify-content: space-between;
  // }
  
  // .col-14 {
  //   flex: 0 0 70%; /* 14/24 = 0.5833 */
  //   max-width: 70%;
  // }
  
  // .col-10 {
  //   flex: 0 0 30%; /* 10/24 = 0.4167 */
  //   max-width: 30%;
  // }
  
  // @media screen and (max-width: 768px) {
  //   .col-14,
  //   .col-10 {
  //     flex: 0 0 100%;
  //     max-width: 100%;
  //   }
  // }

  
.custom-scroll-table::-webkit-scrollbar {
  width: 2px;
}

.custom-scroll-table::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 2px;
}

.custom-scroll-table::-webkit-scrollbar-track {
  background: #f1f1f1; 
}


.custom-scroll-table {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}



