.reusable-grid
  .ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  background-color: #e6e7e9;
  border-inline-end: none;
  padding: 12px;
  border-start-end-radius: initial;
  border-start-start-radius: initial;
  color: var(--text-main-color);
}
.reusable-grid
  .ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > th {
  background-color: #e6e7e9;
  border-inline-end: none;
  padding: 12px;
  border-start-end-radius: initial;
  border-start-start-radius: initial;
  color: var(--text-main-color);
}

.reusable-grid .ant-table-wrapper .ant-table .ant-table-header {
  border-radius: 0;
  min-height: 46px;
}
.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  // border: 1px solid rgb(220, 219, 219);
  // height: 40px;
  border: none;
  height: none;
}

.reusable-grid
  .ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td {
  border-inline-end: none;
  padding: 8px 12px;
  vertical-align: middle;
  color: var(--text-main-color);
}

.reusable-grid .ant-table-wrapper .ant-table-filter-trigger {
  color: #00857c;
  // margin-inline-end: 88px;
}
.reusable-grid .ant-table-wrapper .ant-table-column-sorter {
  color: #00857c;
}

.editable-cell-value-wrap {
  border: 1px solid #d3d6db;
  padding: 4px;
  border-radius: var(--b-radius);
  text-align: left;
}

.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td {
  border-inline-end: none;
  border-bottom: 1px solid #cdd7df;
}

.ant-dropdown
  .ant-table-filter-dropdown
  .ant-dropdown-menu {
  max-height: 150px;
}

.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container {
  border-inline-start: none;
  border-top: none;
}






// .ant-table-wrapper .ant-table-column-title {
//   width: max-content;
// }







.ant-pagination
  .ant-pagination-item {
  border-radius: 2px;
}