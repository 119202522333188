
    .add-alignment-button-align {
        border: 1px solid #00857c;
        border-radius: var(--b-radius);
        color: #454250;
        margin-right: 10px;
      }
      


.grid-column-header-container-align {
    .ant-modal-mask {
        background-color: "none" !important;
    }
}

.edit-alignment-footer{
    background: #ffffff;
    padding: 10px 10px;
    width: 100%;
    justify-content: flex-end;
}

// .edit-alignment-input .ant-input{
//     width: 100%;
//   }
