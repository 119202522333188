.wac-history-custom-modal {
  .ant-modal-content {
    border-radius: var(--b-radius);
  }

  .ant-modal-header {
    border-radius: var(--b-radius);
    display: flex;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 0px;
  }
  .ant-modal-title{
    color: #000000;
    font-weight: 700;
    line-height: 28px;
    font-size: 20px;
    font-style: normal;
  }

  .button-wrapper {
    display: flex;
  }
}

.wac-history-header {
  min-height: 42px;
  background: #F3FCFE;
  padding: 16px;
  border-top: 1px solid #99cecb;
  border-bottom: 1px solid #99cecb;
}

.wac-history-header .ant-row {
  display: flex;
  align-items: center;
  // padding-top: 10px;
}

.align-right-wac{
  justify-content: flex-end;
  // margin-right: 15px;
}

.button-icon {
  border: 1px solid #00857c;
  padding: 8px 12px;
  font-size: 16px;
  color: #00857c;
  border-radius: var(--b-radius);
  height: 40px;
  background-color: #FFFFFF;
}
.wac-history {
  background-color: #f6f7f7;
  padding: 16px;
}

.wac-history-Table{
  padding: 16px;
  background-color: white;
}


.wac-history-Table .reusable-grid .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-header >table >thead>tr>th
  {
    background-color: #E6E7E9;
    border-inline-end:none;
    padding: 10px;
    border-start-end-radius: initial;
    border-start-start-radius:initial;
    // border-radius : 0;
  }


  .wac-history-custom-modal.ant-modal .ant-modal-content{
    padding: 0px;
  }

  .wac-history-Table .reusable-grid .ant-table-wrapper .ant-table.ant-table-small .ant-table-filter-trigger{
    margin-inline-end: 120px;
  }

  .wac-history-Table .reusable-grid .ant-table-wrapper .ant-table-column-sorters {
    display: inline-flex;
  }

  .wac-history-Table .reusable-grid .ant-table-wrapper .ant-table-column-title{
    width: max-content;
  }