.global-input-layout {
  display: flex;
  flex-direction: column;
 .ant-tabs-nav {
  box-shadow: none !important;
  margin-bottom: 0 !important;
 }
  .global-input-header {
    font-size: 20px;
    font-weight: 700;
    padding: 16px 16px 0px 16px;
    color: #000000;
  }
  .global-input-tab-group{
    .ant-tabs-ink-bar {
      background: #00857c;
    }
    .global-input-tab-header-active {
        font-size: 16px;
        font-weight: 600;
        color: #00857c;
      }
      .global-input-tab-header {
        font-size: 16px;
        font-weight: 400;
        color: var( --text-main-color);
      }
  }
  
}

.gloabl-input-tab{
  background-color: #f6f7f7;;
}

.ant-tabs .ant-tabs-tab+.ant-tabs-tab {
  margin-left: 40px;
}

