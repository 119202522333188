.header-nav {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 16px;
  border-top: 2px solid #00857c;
  border-bottom: 1px solid #f0f0f0;

  .sub-header-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    .cce-value {
      width: 146px;
      height: 28px;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #37424a;
    }
    .iqvia-date-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #37424a;
      height: 48px;
      display: flex;
      align-items: center;
    }
    .iqvia-date {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #37424a;
      margin-left: 8px;
    }

    .profile-details {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #37424a;
      height: 46px;
      gap: 8px;
      display: flex;
      align-items: center;

      .user-icon {
        height: 16px;
        width: 16px;
      }
      .log-out-icon {
        color: #00857c;
        font-weight: 700;
        margin-left: 16px;
        margin-top: 5px;
      }
    }
  }
}

.ant-dropdown-menu-item {
  &:hover {
    background-color: #00857c !important;
    color: white !important;
  }
}


.ant-dropdown-menu-item {
  transition: background-color 0.3s, color 0.3s;
}
